import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import HomeImg from '../img/home/my-songs-image.jpg';
import Logo from '../img/header/TahaErkan.png';
import { transition1 } from '../transitions';
import { CursorContext } from '../context/CursorContext';
import SocialsMobile from '../components/SocialsMobile';
import Socials from '../components/Socials';

const Home = () => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={transition1}
      className='section lg:overflow-hidden'
    >
      <div className='container relative mx-auto h-full'>
        {/* text & img wrapper */}
        <div className='flex h-full flex-col items-center justify-center lg:items-stretch'>
          {/* text */}
          <motion.div
            initial={{ opacity: 0, y: '-50%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '-50%' }}
            transition={transition1}
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            className='z-10 flex w-full flex-col items-center justify-center pt-36 pb-14 text-center lg:absolute lg:w-auto lg:items-start lg:pt-0 lg:pb-0 lg:text-left'
          >
            <Link
              onMouseEnter={mouseEnterHandler}
              onMouseLeave={mouseLeaveHandler}
              to={'/'}
              className='lg:inline-block max-w-[500px] lg:max-w-[650px]'
            >
              <img src={Logo} alt='' />
            </Link>
            <SocialsMobile />
            <p className='mb-4 mt-4 text-xl lg:-mt-14 font-extrabold font-primary lg:ml-[200px] lg:mb-12 lg:text-5xl'>
              Hoşgeldiniz
            </p>
            {/* <Link to={'/contact'} className='btn hidden lg:block mb-[30px] lg:ml-[200px]'>
              İletişim
            </Link> */}

            {/* <h1 className='h1'>
              Taha <br /> Erkan
            </h1>
            <p className='mb-4 font-primary text-[26px] lg:mb-12 lg:text-[36px]'>
              Müzisyen, Besteci
            </p> */}
          </motion.div>
          {/* image */}
          <div className='flex max-h-96 justify-center lg:max-h-max'>
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={transition1}
              className='relative overflow-hidden lg:-right-80'
            >
              <motion.img
                whileHover={{ scale: 1.1 }}
                transition={transition1}
                src={HomeImg}
                alt=''
              />
            </motion.div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default Home;
