import React, { useContext } from 'react';
import Socials from './Socials';
import Logo from '../img/header/TahaErkan.png';
import MobileNav from './MobileNav';
import { Link } from 'react-router-dom';
import { CursorContext } from '../context/CursorContext';
import SocialsMobile from './SocialsMobile';

const Header = () => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);

  return (
    <header className='fixed z-30 flex h-[100px] w-full items-center px-[30px] lg:h-[140px] lg:px-[100px]'>
      <div className='flex w-full flex-col justify-between lg:flex-row lg:items-center'>
        <Link
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
          to={'/'}
          className={`lg:inline-block hidden lg:max-w-[400px] ${
            window.location.pathname === '/' ? 'hidden' : ''
          }`}
        >
          <img
            src={Logo}
            alt=''
            className={`${window.location.pathname === '/' ? 'hidden' : ''}`}
          />
        </Link>
        <nav
          className='hidden gap-x-12 font-semibold lg:flex'
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
        >
          <Link to={'/'} className='text-[#696c6d] transition hover:text-primary'>
            Anasayfa
          </Link>
          <Link to={'/about'} className='text-[#696c6d] transition hover:text-primary'>
            Hakkımda
          </Link>
          <Link to={'/my-songs'} className='text-[#696c6d] transition hover:text-primary'>
            Şarkılarım
          </Link>
          <Link to={'/contact'} className='text-[#696c6d] transition hover:text-primary'>
            İletişim
          </Link>
        </nav>
      </div>
      {/* socials */}
      <Socials />
      {/* mobile nav */}
      <MobileNav />
    </header>
  );
};

export default Header;
