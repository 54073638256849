import React, { useContext } from 'react';
import { ImTwitter, ImInstagram, ImYoutube, ImSpotify } from 'react-icons/im';
import { FaTiktok } from 'react-icons/fa';
import { CursorContext } from '../context/CursorContext';

const Socials = () => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);
  return (
    <div
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      className={`ml-24 hidden lg:flex`}
    >
      <ul className='flex gap-x-4'>
        <li>
          <a
            href='https://open.spotify.com/artist/7ypJGgtrZbSrCIGdPbTJK4?si=3xGaJ0BQRpiRVeZFZZMjVg'
            target='_blank'
          >
            <ImSpotify />
          </a>
        </li>
        <li>
          <a href='https://twitter.com/therkanmusic' target='_blank'>
            <ImTwitter />
          </a>
        </li>
        <li>
          <a href='https://www.instagram.com/therkan/' target='_blank'>
            <ImInstagram />
          </a>
        </li>
        <li>
          <a href='https://www.youtube.com/@THERKAN' target='_blank'>
            <ImYoutube />
          </a>
        </li>
        <li>
          <a href='https://www.youtube.com/@THERKAN' target='_blank'>
            <FaTiktok />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Socials;
