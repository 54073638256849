import React, { useContext } from 'react';
// import images
import kumTanesi from '../img/my-songs/kum-tanesi.jpeg';
import seniOzledim from '../img/my-songs/seni-ozledim.jpeg';
import savrulmusum from '../img/my-songs/savrulmusum.jpeg';

// import link
import { Link } from 'react-router-dom';
// import motion
import { motion } from 'framer-motion';
// import transition
import { transition1 } from '../transitions';
// import context
import { CursorContext } from '../context/CursorContext';
import SocialsMobile from '../components/SocialsMobile';

const Portfolio = () => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);
  return (
    <motion.section
      initial={{ opacity: 0, y: '100%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '100%' }}
      transition={transition1}
      className='section lg:overflow-hidden'
    >
      <div className='container relative mx-auto h-full'>
        <div className='flex h-full flex-col items-center justify-center gap-x-24 px-8 pt-24 pb-8 text-center lg:flex-row lg:justify-start lg:pt-36 lg:text-left'>
          {/* text */}
          <motion.div
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            initial={{ opacity: 0, y: '-80%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '-80%' }}
            transition={transition1}
            className='flex flex-col lg:items-start justify-center items-center'
          >
            <SocialsMobile />
            <h1 className='h1'>Şarkılarım</h1>
            <p className='my-12 max-w-sm'>
              Şarkılarımın kapak resimlerine tıklayarak spotifyda ulaşabilirsiniz
              <br />
              <br />
            </p>
            <div className='mb-12 max-w-sm'>
              <iframe
                style={{ borderRadius: '12px' }}
                src='https://open.spotify.com/embed/artist/7ypJGgtrZbSrCIGdPbTJK4?utm_source=generator&theme=0'
                width='100%'
                height='380'
                frameBorder='0'
                allowfullscreen=''
                allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
                loading='lazy'
              ></iframe>
            </div>
          </motion.div>
          {/* image grid */}
          <div
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            className={`hidden lg:grid grid-cols-2 lg:gap-2 `}
          >
            {/* image */}
            <div className='h-[187px] max-w-[250px] overflow-hidden bg-accent lg:h-[220px] lg:max-w-[320px]'>
              <a href=''>
                <img
                  className='h-full object-cover transition-all duration-500 hover:scale-110 lg:h-[220px]'
                  src={kumTanesi}
                  alt=''
                />
              </a>
            </div>
            <div className='h-[187px] max-w-[250px] overflow-hidden bg-accent lg:h-[220px] lg:max-w-[320px]'>
              <a href=''>
                <img
                  className='h-full object-cover transition-all duration-500 hover:scale-110 lg:h-[220px]'
                  src={seniOzledim}
                  alt=''
                />
              </a>
            </div>
            <div className='h-[187px] max-w-[250px] overflow-hidden bg-accent lg:h-[220px] lg:max-w-[320px]'>
              <a href=''>
                <img
                  className='h-full object-cover transition-all duration-500 hover:scale-110 lg:h-[220px]'
                  src={savrulmusum}
                  alt=''
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default Portfolio;
