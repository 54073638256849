import React, { useContext } from 'react';
import About1 from '../img/about/about-me-1.jpg';
import { motion } from 'framer-motion';
import { transition1 } from '../transitions';
import { CursorContext } from '../context/CursorContext';
import SocialsMobile from '../components/SocialsMobile';

const About = () => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);
  return (
    <motion.section
      initial={{ opacity: 0, y: '100%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '100%' }}
      transition={transition1}
      className='section lg:overflow-hidden'
    >
      <div
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}
        className='container relative mx-auto h-full'
      >
        {/* text & img wrapper */}
        <div className='flex h-full flex-col items-center justify-center gap-x-0 text-center lg:flex-row lg:pt-16 lg:text-left'>
          {/* image */}
          <div className='order-2 max-h-96 flex-1 overflow-hidden lg:order-none lg:max-h-max'>
            <img src={About1} alt='' />
          </div>
          {/* text */}
          <motion.div
            initial={{ opacity: 0, y: '-80%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '-80%' }}
            transition={transition1}
            className='z-10 flex flex-1 flex-col items-center justify-center pt-36 pb-14 lg:w-auto lg:items-start lg:pt-0'
          >
            <SocialsMobile />
            <h1 className='h1'>Hakkımda</h1>
            <p className='mb-12 max-w-sm lg:mb-6'>
              <span className='font-extrabold'>Selam ben Taha</span>. 1998 yılında Ankara'da doğdum.
              Müziğe 5 yaşında klasik gitar çalmayı öğrenerek başladım. O günden bu güne müzikle
              ilgili her şeyi öğrenmek için çaba sarfediyorum.
            </p>
            <p className='mb-12 max-w-sm lg:mb-6'>
              Gazi Üniversitesi Radyo TV-Sinema bölümünü bitirdim. Üniversite hayatımda
              fotoğrafçılık ve tasarımla tanışarak sanatın başka alanlarıyla da ilgilenmenin
              müziğime katkısı olabileceğini anladım. Müziğim için hep güçlü duygulardan beslendim.
              Aşk, hüzün, sevinç... Edebiyata olan ilgimle söz yazmaya ve beste yapmaya başladım.
            </p>
            <p className='mb-12 max-w-sm lg:mb-6'>
              2021 yılı Aralık ayında ilk teklim
              <a
                href='https://open.spotify.com/track/5ozlMhn15hAfeOq83Aq6Gd?si=655623e4b8a5470a'
                className='font-black'
                target='_blank'
              >
                &nbsp;Kum Tanesi
              </a>
              'ni çıkardım. Klibimi kendim çektim ve post production'unu üstlenerek her yönüyle bana
              ait olan bir çalışma ortaya çıkarmaya çalıştım. 2022 yılı Mart ayında ikinci teklim
              <a
                href='https://open.spotify.com/track/5CDLam4YJusF1H5ZDjHrv9?si=33c0cd28a43b419d'
                className='font-black'
                target='_blank'
              >
                &nbsp;Seni Özledim&nbsp;
              </a>
              yayınlandı.
              <br />
              2022 Ağustos ayında üçüncü teklim <br />
              <a
                href='https://open.spotify.com/track/0nyo9n2H0SIXZDWO1hrfGX?si=f007cd191dee4fca'
                className='font-black'
                target='_blank'
              >
                Savrulmuşum&nbsp;
              </a>
              yayınlandı ve kısa sürede oldukça beğenildi. Yeni şarkılar için çalışmalarım devam
              etmekte. Umarım müziğimde kendinizden parçalar bulup benimser ve keyif alırsınız. Yeni
              çalışmalarda görüşmek üzere.
            </p>
            <h3 className='h3'>Öpüldünüz :)</h3>
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default About;
