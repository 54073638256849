import React, { useContext } from 'react';
import ContactImg from '../img/contact/contact-dikey.jpg';
import { motion } from 'framer-motion';
import { transition1 } from '../transitions';
import { CursorContext } from '../context/CursorContext';
import SocialsMobile from '../components/SocialsMobile';

const Contact = () => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);

  return (
    <motion.section
      initial={{ opacity: 0, y: '100%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '100%' }}
      transition={transition1}
      className='section bg-white lg:overflow-hidden'
    >
      <div className='container mx-auto h-full'>
        <div className='pt-18 flex h-full flex-col items-center justify-center gap-x-8 px-16 text-center lg:flex-row lg:justify-start lg:pt-36 lg:text-left'>
          {/* image */}
          <motion.div
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            initial={{ opacity: 0, y: '100%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '100%' }}
            transition={{ transition: transition1, duration: 1.5 }}
            className='lg:block'
          >
            <img src={ContactImg} alt='' className='max-h-[500px] lg:max-h-[750px]' />
          </motion.div>

          {/* bg */}
          <motion.div
            initial={{ opacity: 0, y: '100%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '100%' }}
            transition={transition1}
            className='absolute bottom-0 left-0 right-0 top-72 -z-10 hidden bg-[#eef7f9] lg:flex'
          ></motion.div>

          <div
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            className='px-4 lg:flex-1 lg:pt-32'
          >
            <h1 className='h1'>Bana Ulaşın</h1>

            <div className='flex flex-col gap-y-4'>
              <div className='h-[60px] w-full bg-transparent pl-3 font-secondary outline-none placeholder:text-[#757879]'>
                <a href='mailto:therkan.music@gmail.com'>
                  <button className='btn my-[30px] mx-auto self-start lg:mx-0'>
                    therkan.music@gmail.com
                    <div className='ml-10'>
                      <SocialsMobile />
                    </div>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default Contact;
